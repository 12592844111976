<template>
  <div class="ma-2">
    <v-card-title> Features </v-card-title>
    <div>
      <v-form ref="form" v-model="valid" v-if="features">
        <v-row style="margin-top: 1.5rem">
          <v-col
            style="margin-top: -2.5rem"
            v-for="(feature, index) in features"
            :key="index"
            v-show="!feature.disabled"
            cols="6"
          >
            <v-switch
              @change="featureUpdate"
              v-model="feature.active"
              :disabled="feature.disabled"
            >
              <template v-slot:label>
                <feature-icon v-model="feature.value"></feature-icon>
                {{ feature.text }}
              </template>
            </v-switch>
          </v-col>
        </v-row>
      </v-form>
      <div v-else class="text-center">
        <v-progress-circular
          indeterminate
          size="50"
          width="5"
          color="primary"
          class="mt-7"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import feature from '@/mixins/feature';
import user from '@/mixins/user';
export default {
  mixins: [feature, user],
  components: {
    FeatureIcon: () => import('@/components/Core/Features/FeatureIcon.vue')
  },
  data: () => ({
    valid: true,
    defaultFeatureData: []
  }),
  computed: {
    features: {
      get() {
        const userFeatures = this.$store.getters.user?.profile?.features || [];
        let res = [...this.defaultFeatureData]
        res = res.map(ft=>{
          const userFeature = userFeatures.filter(i=>i?.value===ft?.value)[0]
          let active = true
          if(userFeature?.active===false){
            active=false
          }

          return {
            ...ft,
            active
          }
        })
        return res
      },
      set(data) {
        return data;
      }
    }
  },
  async mounted() {
    this.generateFeatures();
  },
  methods: {
    async generateFeatures() {
      let features = await this.defaultFeatures();
      features = [...features.data];
      for (let feat of features) {
        if (!feat.disabled) {
          feat.active = true;
        } else {
          feat.active = false;
        }
      }
      features.sort((a,b) =>{
        return Number(a.id || 0) - Number(b.id || 0)
      })
      this.defaultFeatureData = features
    },
    sortFeatures() {
      let f = [...this.$store.getters.user.profile.features];
      f.map(e => (e.id = this.findOrigFeatures(e.value).id || 999));
      f.sort((a, b) => parseInt(b.id) - parseInt(a.id)).reverse();
      this.$store.commit('userFeatures', f);
    },
    findOrigFeatures(val) {
      let f = this.$store.state.features.filter(e => e.value == val);
      if (f) {
        f = f[0];
      } else {
        f = {};
      }
      return f;
    },
    getUserFeature(f) {
      return this.features.filter(e => e.value == f)[0];
    },
    buildFeatures() {
      let features = [];
      this.$store.getters.features.forEach(f => {
        let uf = this.getUserFeature(f.value);
        if (uf) {
          features.push({ ...f, ...uf, text: f.text });
        } else {
          if (!f.disabled) {
            f.active = true;
          }
          features.push({ ...f });
        }
      });
      return features;
    },
    async getOrCreateFeatures() {
      let profile = await this.userProfile();
      if (!profile.features) {
        await this.initFeatures(await this.currentUser());
      }

      let f = this.buildFeatures();
      if (f.length) {
        this.$store.commit('userFeatures', f);
      }

      this.features.forEach(feature => {
        let origFeature = this.isFeatureDisabled(feature);
        feature.disabled = origFeature.disabled;
        feature.text = origFeature.text;
        if (feature.disabled) {
          feature.active = false;
        }
      });

      // this.$store.getters.features.forEach((feature) => {
      //   let featureFound = this.features.some((e) => {
      //     return e.value == feature.value;
      //   });
      //   if (!featureFound) {
      //     let featureData = { ...feature };
      //     if (!featureData.disabled) {
      //       featureData.active = true;
      //     }
      //     this.$store.getters.user.profile.features.push(featureData);
      //     this.changeProfileData({ features: this.features });
      //   }
      // });
      // this.changeProfileData({ features: this.features });
    },
    async featureUpdate() {
      await this.changeProfileData({ features: this.features });
    },
    isFeatureDisabled(feature) {
      let res = { disabled: false, text: feature.text };
      this.$store.getters.features.forEach(ft => {
        if (ft.value == feature.value) {
          res.text = ft.text;
          if (ft.disabled) {
            res.disabled = true;
          }
        }
      });
      return res;
    }
  }
};
</script>

<style></style>
